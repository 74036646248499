import { setUser, withSentry as withNextSentry } from "@sentry/nextjs";
import { uuid4 } from "@sentry/utils";
import Analytics from "analytics-node";
import { IncomingMessage, ServerResponse } from "http";
import { NextApiHandler } from "next";
import { Session } from "next-auth";
import { getSession } from "next-auth/client";
import { setCookie } from "nookies";

export interface IncomingRequest extends IncomingMessage {
  cookies: {
    [key: string]: string;
  };
}

export type TrackFunction = (message: {
  event: string;
  properties?: unknown;
  integrations?: Record<string, boolean>;
}) => void;

type Identity =
  | { userId: string | number }
  | { userId?: string | number | undefined; anonymousId: string | number };

// this is how we deal with per-request context, server-side
const trackFunctionRequestMap = new WeakMap<IncomingRequest, TrackFunction>();

const setupTrackFunction = (
  req: IncomingRequest,
  res: ServerResponse,
  session: Session | null
): TrackFunction => {
  let anonymousId;
  if (req.cookies["ajs_anonymous_id"]) {
    anonymousId = req.cookies["ajs_anonymous_id"];
  } else {
    anonymousId = uuid4();
    setCookie({ res }, "ajs_anonymous_id", anonymousId, { path: "/" });
  }
  const analytics = new Analytics(
    process.env.NEXT_SERVER_SEGMENT_API_KEY ||
      process.env.NEXT_PUBLIC_SEGMENT_API_KEY ||
      ""
  );
  if (process.env.NODE_ENV !== "production") {
    console.log(`[SEGMENT] got analytics ${analytics} for session ${session}`);
  }
  let identity: Identity = {
    anonymousId,
  };

  if (session) {
    const { user } = session;
    identity = {
      userId: user.id,
    };
    analytics.identify({
      ...identity,
      traits: {
        email: session.user.email,
        firstName: session.user.firstName,
        lastName: session.user.lastName,
      },
    });
  }

  return (message: {
    event: string;
    properties?: unknown;
    integrations?: Record<string, boolean>;
  }) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(
        `[SEGMENT] tracking ${JSON.stringify(identity)} ${JSON.stringify(
          message
        )}`
      );
    }

    const identityTraits = session
      ? {
          email: session.user.email,
          firstName: session.user.firstName,
          lastName: session.user.lastName,
        }
      : {};

    analytics.track({
      ...identity,
      ...message,
      context: {
        traits: {
          ...identityTraits,
        },
      },
    });
  };
};

/**
 * Gets a function you can use for tracking a Segment event
 *
 * This function ensures the currently logged-in user from `next-auth` is associated with the event.
 *
 */
export const getTrackFunction = async (
  req: IncomingRequest,
  res: ServerResponse
): Promise<TrackFunction> => {
  let maybeTrackFunction = trackFunctionRequestMap.get(req);

  if (!maybeTrackFunction) {
    if (process.env.NODE_ENV !== "production") {
      console.log(`[SEGMENT] Couldn't find track function in cache`);
    }
    const session = await getSession({ req });
    maybeTrackFunction = setupTrackFunction(req, res, session);
    trackFunctionRequestMap.set(req, maybeTrackFunction);
  }

  return maybeTrackFunction;
};

/**
 * Wraps a server-side API function with Sentry logging and Segment support
 *
 * Watch out! Do not apply this to `next-auth` API functions, because these are called here and would cause an infinite loop.
 *
 */
export const withServerSideSentryAndSegment = (
  originalHandler: NextApiHandler
): NextApiHandler => {
  const handlerWithIdentification: NextApiHandler = async (req, res) => {
    const session = await getSession({ req });

    if (session) {
      const { user } = session;
      if (process.env.NODE_ENV !== "production") {
        console.log(`[SENTRY] Identifying current user ${user.email}`);
      }
      const sentryUser = {
        email: user.email,
        id: user.id,
        username: `${user.firstName} ${user.lastName}`,
      };
      setUser(sentryUser);
    } else {
      if (process.env.NODE_ENV !== "production") {
        console.log(`[SENTRY] No current user`);
      }
      setUser(null);
    }

    const analytics = setupTrackFunction(req, res, session);
    trackFunctionRequestMap.set(req, analytics);

    return originalHandler(req, res);
  };

  return withNextSentry(handlerWithIdentification);
};
