import { AnalyticsBrowser } from "@segment/analytics-next";
import {
  addDays,
  differenceInBusinessDays,
  differenceInCalendarDays,
  eachDayOfInterval,
  format,
  isSameDay,
  startOfToday,
  startOfTomorrow,
} from "date-fns";
import {
  GetSubscriptionQuoteQuery,
  KitInventoryType,
  KitItemType,
  Maybe,
  Package,
  SelectedKitItem,
  SubscriptionFragment,
  UpdateRiderInput,
  Viewer,
} from "generated/graphql";
import { GetServerSidePropsContext } from "next";
import { parseCookies } from "nookies";
import { useEffect, useState } from "react";

import { useSegment } from "../hooks/useSegment";
import {
  getTrackFunction,
  TrackFunction,
} from "./withServerSideSentryAndSegment";

type TrackingOptions = {
  all?: boolean;
  gaOnly?: boolean;
  fbOnly?: boolean;
  roktOnly?: boolean;
};

type Product = {
  product_id: number | string;
  sku: string;
  category: string;
  name: string;
  brand: Maybe<string> | undefined;
  variant: string;
  price: number;
  quantity: number;
  image_url: string | undefined;
  currency: string;
};

const legacySignupFunnelSteps = {
  SIGNUP: "Signup - 0. SIGNUP",
  ACCOUNT: "Signup - 1. ACCOUNT",
  CONTACT: "Signup - 2. CONTACT",
  TERMS: "Signup - 3. TERMS",
  ABOUT_YOU: "Signup - 3.1 About You",
  BIKE: "Signup - 4. BIKE + KIT",
  DELIVERY: "Signup - 5. DELIVERY",
  PAYMENT: "Signup - 6. PAYMENT",
  COMPLETED: "Signup - 7. COMPLETED",
} as const;

function trackLegacySignupFunnelEvents(
  tracker: ClientOrServerSegment,
  step: keyof typeof legacySignupFunnelSteps,
  bikeName?: string
) {
  // for the legacy funnel event, we need to produce:
  // - an event with a name taken from legacySignupFunnelSteps for just GA
  // - an event named `F - <name>` for just FB
  // - a start onboarding funnel event for everything else
  trackGenericEvent(
    tracker,
    `${legacySignupFunnelSteps[step]} - ecom`,
    { category: "Signup", label: bikeName },
    { gaOnly: true }
  );
  trackGenericEvent(tracker, "Start onboarding funnel step", {
    funnelStep: legacySignupFunnelSteps[step],
    acValue: legacySignupFunnelSteps[step],
    bikeName,
  });
}

export enum SignupFunnelStep {
  City,
  Plan,
  Account,
  Kit,
  Pay,
  Confirmed,
}

const signupFunnelToLegacyMap = {
  [SignupFunnelStep.City]: "SIGNUP",
  [SignupFunnelStep.Plan]: "BIKE",
  [SignupFunnelStep.Account]: "DELIVERY",
  [SignupFunnelStep.Kit]: "BIKE",
  [SignupFunnelStep.Pay]: "PAYMENT",
  [SignupFunnelStep.Confirmed]: "COMPLETED",
} as const;

function signupFunnelStepStarted(
  tracker: AnalyticsBrowser,
  step: SignupFunnelStep,
  bikeName?: string
) {
  // TODO track a bit more than just this.
  trackGenericEvent(tracker, "Checkout Step Started", {
    checkoutType: "standard-2021",
    funnelStep: step,
    acValue: step,
    bikeName,
  });
  trackLegacySignupFunnelEvents(
    tracker,
    signupFunnelToLegacyMap[step],
    bikeName
  );
}

export function useTrackSignupFunnelStepStarted(
  step: SignupFunnelStep,
  bikeName?: string,
  canFire = true
) {
  const segment = useSegment();
  const [fired, setFired] = useState(false);
  useEffect(() => {
    if (canFire && segment && !fired) {
      if (step === SignupFunnelStep.City) {
        trackGenericEvent(segment, "Start onboarding funnel", undefined, {
          fbOnly: true,
        });
      } else if (step === SignupFunnelStep.Account) {
        trackGenericEvent(segment, "Delivery", undefined, { fbOnly: true });
      }
      signupFunnelStepStarted(segment, step, bikeName);
      setFired(true);
    }
  }, [segment, fired, step, canFire, bikeName]);
}

const FUNNEL_VERSION = 2;
export enum CancelFunnelStep {
  START_CANCELLATION = "Start Cancellation",
  COMPLETE_CANCELLATION = "Complete Cancellation",
}

const cancelFunnelSteps = {
  STEP_1: {
    stepName: "Category",
    pageName: "step-1",
    category: null,
  },
  STEP_2: {
    stepName: "Switch Plan",
    pageName: "step-2",
    category: "Lower my subscription",
  },
  STEP_2_1: {
    stepName: "Discount",
    pageName: "step-2-1",
    category: "Lower my subscription",
  },
  STEP_3: {
    stepName: "Help ride",
    pageName: "step-3",
    category: "Help me ride more",
  },
  STEP_4: {
    stepName: "Pause subscription",
    pageName: "step-4",
    category: "Let me pause my subscription for a few weeks",
  },
  STEP_5: {
    stepName: "Issues category",
    pageName: "step-5",
    category: "Fix an issue with my Buzzbike",
  },
  STEP_5_1: {
    stepName: "Something is broken",
    pageName: "step-5-1",
    category: "Fix an issue with my Buzzbike",
  },
  STEP_5_2: {
    stepName: "My Buzzbike is not the right size",
    pageName: "step-5-2",
    category: "Fix an issue with my Buzzbike",
  },
  STEP_6: {
    stepName: "Cancel reason",
    pageName: "step-6",
    category: "Nothing, I need to cancel my subscription",
  },
  STEP_6_1: {
    stepName: "Benefits/Bike Cost Calculator",
    pageName: "step-6-1",
    category: "Nothing, I need to cancel my subscription",
  },
  NO_MAINTENANCE: {
    stepName: "No Maintenance",
    pageName: "no-maintenance",
    category: "Nothing, I need to cancel my subscription",
  },
  STEP_6_2: {
    stepName: "Interstitial",
    pageName: "step-6-2",
    category: "Nothing, I need to cancel my subscription",
  },
  STEP_6_3: {
    stepName: "Ready To Cancel",
    pageName: "step-6-3",
    category: "Nothing, I need to cancel my subscription",
  },
  STEP_7: {
    stepName: "Buy your buzzbike",
    pageName: "step-7",
    category: "Buy your buzzbike",
  },
  STEP_8: {
    stepName: "Power down to Buzzbike-CTY",
    pageName: "step-8",
    category: "Power down to Buzzbike-CTY",
  },
} as const;

export function trackCancellationFunnelStarted(tracker: ClientOrServerSegment) {
  trackGenericEvent(tracker, "Cancellation Funnel Started", {
    funnel_version: FUNNEL_VERSION,
    step_name: CancelFunnelStep.START_CANCELLATION,
  });
}

export function trackCancellationFunnelCompleted(
  tracker: ClientOrServerSegment
) {
  trackGenericEvent(tracker, "Cancellation Funnel Completed", {
    funnel_version: FUNNEL_VERSION,
    step_name: CancelFunnelStep.COMPLETE_CANCELLATION,
  });
  window?.VWO?.event("cancellationFunnelCompleted");
}

export function useCancellationTracking(
  step: keyof typeof cancelFunnelSteps,
  canFire = true
) {
  const segment = useSegment();
  const [fired, setFired] = useState(false);
  const { pageName, stepName, category } = cancelFunnelSteps[step];

  useEffect(() => {
    if (canFire && segment && !fired) {
      trackGenericEvent(segment, "Cancellation Funnel Screen Viewed", {
        funnel_step: pageName,
        funnel_version: FUNNEL_VERSION,
        step_name: stepName,
        acValue: `${pageName}. ${stepName}`,
        category,
      });
      setFired(true);
    }
  }, [canFire, category, fired, pageName, segment, stepName]);
}

export function useFailedCheckTracking() {
  const segment = useSegment();
  const [fired, setFired] = useState(false);

  useEffect(() => {
    if (segment && !fired) {
      trackGenericEvent(segment, "Failed Check Viewed");
      setFired(true);
    }
  }, [fired, segment]);
}

export function useBuyYourBikeTracking() {
  const segment = useSegment();
  const [fired, setFired] = useState(false);

  useEffect(() => {
    if (segment && !fired) {
      trackGenericEvent(segment, "Buy Your Bike Viewed");
      setFired(true);
    }
  }, [fired, segment]);
}

export function useBuyYourBikeNonRiderTracking() {
  const segment = useSegment();
  const [fired, setFired] = useState(false);

  useEffect(() => {
    if (segment && !fired) {
      trackGenericEvent(segment, "Buy Your Bike for non-rider Viewed");
      setFired(true);
    }
  }, [fired, segment]);
}

export function trackClaimMyDiscount(
  tracker: ClientOrServerSegment,
  discountCode: string
) {
  trackGenericEvent(tracker, "Claim My Discount", { discountCode });
}

export function trackClaimReward(tracker: ClientOrServerSegment, key: string) {
  trackGenericEvent(tracker, "Claim My Reward", { key });
}

export function trackCancellationBranchClicked(
  tracker: ClientOrServerSegment,
  branch: string
) {
  trackGenericEvent(tracker, "Cancellation Branch Clicked", { branch });
}

export function trackFixedPlanDetailViewed(
  tracker: ClientOrServerSegment,
  properties: {
    packageSlug: string;
    termLengthMonths?: number | null;
  }
) {
  trackGenericEvent(tracker, "Fixed Plan Detail Viewed", properties);
}

export function trackSwitchBikeButtonClicked(
  tracker: ClientOrServerSegment,
  switchTo: string
) {
  trackGenericEvent(tracker, "Switch Bike Button Clicked", { switchTo });
}

type ClientOrServerSegment = AnalyticsBrowser | TrackFunction;

const SEGMENT_INTEGRATION_GA = "Google Analytics";
const SEGMENT_INTEGRATION_FB_PIXEL = "Facebook Pixel";
const SEGMENT_INTEGRATION_FB_CONVERSIONS = "Facebook Conversions API (Actions)";
const SEGMENT_INTEGRATION_ROKT = "Rokt";

function getClientSideIntegrations(
  options: TrackingOptions
): Record<string, boolean> {
  // TODO decide if GA + FB should _only_ get legacy events...
  if (options.all) {
    return { [SEGMENT_INTEGRATION_ROKT]: false };
  } else if (options.gaOnly) {
    return {
      All: false,
      [SEGMENT_INTEGRATION_GA]: true,
    };
  } else if (options.fbOnly) {
    return {
      All: false,
      [SEGMENT_INTEGRATION_FB_PIXEL]: true,
    };
  } else if (options.roktOnly) {
    return {
      All: false,
      [SEGMENT_INTEGRATION_ROKT]: true,
    };
  }
  return {
    [SEGMENT_INTEGRATION_FB_CONVERSIONS]: false,
    [SEGMENT_INTEGRATION_FB_PIXEL]: false,
    [SEGMENT_INTEGRATION_ROKT]: false,
  };
}

function getServerSideIntegrations(
  options: TrackingOptions
): Record<string, boolean> {
  if (options.all) {
    return { [SEGMENT_INTEGRATION_ROKT]: false };
  } else if (options.gaOnly) {
    return {
      All: false,
      [SEGMENT_INTEGRATION_GA]: true,
    };
  } else if (options.fbOnly) {
    return {
      All: false,
      [SEGMENT_INTEGRATION_FB_CONVERSIONS]: true,
    };
  } else if (options.roktOnly) {
    return {
      All: false,
      [SEGMENT_INTEGRATION_ROKT]: true,
    };
  }
  return {
    [SEGMENT_INTEGRATION_FB_PIXEL]: false,
    [SEGMENT_INTEGRATION_ROKT]: false,
  };
}

function trackGenericEvent(
  tracker: ClientOrServerSegment,
  eventName: string,
  properties?: Record<string, unknown>,
  options: TrackingOptions = {}
) {
  if (typeof tracker !== "function") {
    tracker.track(eventName, properties, {
      integrations: getClientSideIntegrations(options),
    });
  } else {
    tracker({
      event: eventName,
      properties,
      integrations: getServerSideIntegrations(options),
    });
  }
}

export function trackDeliveryDateSelected(
  tracker: ClientOrServerSegment,
  availableDeliveryDates: Date[],
  selectedDate: Date
) {
  const today = startOfToday();
  const tomorrow = startOfTomorrow();
  const earliestDate = availableDeliveryDates[0];
  let numberOfAvailableDateIn7Days = 0;

  eachDayOfInterval({
    start: tomorrow,
    end: addDays(tomorrow, 7),
  }).forEach((date) => {
    const isInclude = availableDeliveryDates.some((availableDate) =>
      isSameDay(availableDate, date)
    );
    if (isInclude) numberOfAvailableDateIn7Days++;
  });

  const properties = {
    isEarliest: isSameDay(earliestDate, selectedDate),
    toEarliestInDays: differenceInCalendarDays(earliestDate, today),
    toEarliestInWorkingDays: differenceInBusinessDays(earliestDate, today),
    toSelectedInDays: differenceInCalendarDays(selectedDate, today),
    toSelectedInWorkingDays: differenceInBusinessDays(selectedDate, today),
    dayOfWeek: format(selectedDate, "EEEE"),
    numberOfAvailableDateIn7Days,
  };

  trackGenericEvent(tracker, "Delivery date Selected", properties);
}

export function useProductListViewed(kitItems?: KitItemType[], canFire = true) {
  const segment = useSegment();
  const [fired, setFired] = useState(false);
  useEffect(() => {
    if (canFire && !fired && kitItems) {
      trackProductListViewed(segment, kitItems);
      setFired(true);
    }
  }, [segment, fired, canFire, kitItems]);
}

function trackProductListViewed(
  tracker: AnalyticsBrowser,
  kitItems: KitItemType[]
) {
  const products: Product[] = [];

  kitItems.forEach((kitItem) => {
    kitItem.variations?.forEach((variation) => {
      products.push({
        product_id: variation.kitInventoryId,
        sku: variation.code,
        category: kitItem.kitCategory.categoryName,
        name: kitItem.name,
        brand: kitItem.brandName,
        variant: variation.name,
        price: variation.price,
        quantity: variation.quantity,
        image_url: variation.images?.find((image) => image.isFeatured)
          ?.imageUrl,
        currency: "GBP",
      });
    });
  });

  const properties = {
    list_id: "kit-items",
    category: "Kit Items",
    products,
  };
  trackGenericEvent(tracker, "Product List Viewed", properties);
}

export function trackBikeListViewed(
  tracker: AnalyticsBrowser,
  packages: Package[]
) {
  const products: Product[] = [];

  packages.forEach(({ bike, stickerPrice, termLengthMonths, packageSlug }) => {
    products.push({
      product_id: packageSlug,
      sku: bike?.name || "Buzzbike",
      category: "Bikes",
      name: bike?.name || "Buzzbike",
      brand: "Buzzbike",
      variant: bike?.name || "Buzzbike",
      price: stickerPrice / 100,
      quantity: termLengthMonths || 1,
      image_url: bike?.images?.find((image) => image.isFeatured)?.imageUrl,
      currency: "GBP",
    });
  });

  const properties = {
    list_id: "bike-items",
    category: "Bikes",
    products,
  };
  trackGenericEvent(tracker, "Product List Viewed", properties, { all: true });
}

export function trackProductViewed(
  tracker: AnalyticsBrowser,
  kitItem: KitItemType,
  variation: KitInventoryType
) {
  const properties = {
    product_id: variation.kitInventoryId,
    sku: variation.code,
    category: kitItem.kitCategory.categoryName,
    name: kitItem.name,
    brand: kitItem.brandName,
    variant: variation.name,
    price: variation.price,
    quantity: variation.quantity,
    image_url: variation.images?.find((image) => image.isFeatured)?.imageUrl,
    currency: "GBP",
  };
  trackGenericEvent(tracker, "Product Viewed", properties);
}

export function trackProductAddedOrRemove(
  tracker: AnalyticsBrowser,
  isAdded: boolean,
  kitItem?: KitItemType,
  variation?: KitInventoryType
) {
  if (kitItem && variation) {
    const properties = {
      cart_id: `cart_${tracker.instance?.user().anonymousId()}`,
      product_id: variation.kitInventoryId,
      sku: variation.code,
      category: kitItem.kitCategory.categoryName,
      name: kitItem.name,
      brand: kitItem.brandName,
      variant: variation.name,
      price: variation.price,
      quantity: variation.quantity,
      image_url: variation.images?.find((image) => image.isFeatured)?.imageUrl,
      currency: "GBP",
    };
    trackGenericEvent(
      tracker,
      isAdded ? "Product Added" : "Product Removed",
      properties
    );
  }
}

export function trackBikeAddedOrRemove(
  tracker: AnalyticsBrowser,
  isAdded: boolean,
  plan: Package
) {
  const { bike, packageSlug, stickerPrice, termLengthMonths } = plan;
  const properties = {
    cart_id: `cart_${tracker.instance?.user().anonymousId()}`,
    product_id: packageSlug,
    sku: bike?.name || "Buzzbike",
    category: "Bikes",
    name: bike?.name || "Buzzbike",
    brand: "Buzzbike",
    variant: bike?.name || "Buzzbike",
    price: stickerPrice / 100,
    quantity: termLengthMonths || 1,
    image_url: bike?.images?.find((image) => image.isFeatured)?.imageUrl,
    currency: "GBP",
  };
  trackGenericEvent(
    tracker,
    isAdded ? "Product Added" : "Product Removed",
    properties,
    { all: true }
  );
}

export function useCheckoutStarted(
  packageSlug: string,
  email: string,
  subscriptionQuoteQuery?: GetSubscriptionQuoteQuery,
  selectedKitItems?: SelectedKitItem[],
  coupon?: string,
  canFire = true
) {
  const segment = useSegment();
  const [fired, setFired] = useState(false);
  useEffect(() => {
    if (canFire && !fired && subscriptionQuoteQuery) {
      trackCheckoutStarted(
        segment,
        subscriptionQuoteQuery,
        packageSlug,
        email,
        selectedKitItems,
        coupon
      );
      setFired(true);
    }
  }, [
    segment,
    fired,
    canFire,
    subscriptionQuoteQuery,
    selectedKitItems,
    packageSlug,
    email,
    coupon,
  ]);
}

function trackCheckoutStarted(
  tracker: AnalyticsBrowser,
  subscriptionQuoteQuery: GetSubscriptionQuoteQuery,
  packageSlug: string | number,
  email: string,
  _selectedKitItems?: SelectedKitItem[],
  coupon?: string
) {
  const subscriptionQuote = subscriptionQuoteQuery.getSubscriptionQuote;
  if (!subscriptionQuote) return;
  // let total = 0;
  // const kitItems: Array<Product | undefined> =
  //   selectedKitItems?.map((selectedKit) => {
  //     const kitItem = subscriptionQuote.selectedKitItems?.find((i) =>
  //       i.variations?.some(
  //         (v) => v.kitInventoryId === selectedKit.kitInventoryId
  //       )
  //     );
  //     if (kitItem) {
  //       const variation = kitItem?.variations?.find(
  //         (v) => v.kitInventoryId === selectedKit.kitInventoryId
  //       );
  //       if (variation) {
  //         total += variation.price * selectedKit.quantity;
  //         return {
  //           product_id: variation.kitInventoryId,
  //           sku: variation.code,
  //           category: kitItem.kitCategory.categoryName,
  //           name: kitItem.name,
  //           brand: kitItem.brandName,
  //           variant: variation.name,
  //           price: variation.price,
  //           quantity: selectedKit.quantity,
  //           image_url: variation.images?.find((image) => image.isFeatured)
  //             ?.imageUrl,
  //           currency: "GBP",
  //         };
  //       }
  //     }
  //   }) || [];

  const { selectedBike, subscription } = subscriptionQuote;
  const bikeItem = {
    product_id: packageSlug,
    sku: selectedBike?.name || "Buzzbike",
    category: "Bikes",
    name: selectedBike?.name || "Buzzbike",
    brand: "Buzzbike",
    variant: selectedBike?.name || "Buzzbike",
    price: subscription.stickerPrice / 100,
    quantity:
      (subscription.__typename === "MonthlyQuote"
        ? subscription.monthlyCommitment?.lengthMonths
        : subscription.fixedTermCommitment.lengthMonths) || 1,
    image_url: selectedBike?.images?.find((image) => image.isFeatured)
      ?.imageUrl,
    currency: "GBP",
  };
  // total = total + bikeItem.price * bikeItem.quantity;
  // kitItems.push(bikeItem);

  const properties = {
    order_id: `order_${tracker.instance?.user().id()}`,
    affiliation: "Signup Page",
    currency: "GBP",
    products: [bikeItem],
    coupon,
  };

  trackGenericEvent(tracker, "Checkout Started", properties, { all: true });
  trackGenericEvent(
    tracker,
    "Checkout Started",
    {
      email,
      bikeType: bikeItem.name,
      conversiontype: "Checkout",
      confirmationref: tracker.instance?.user().id(),
    },
    { roktOnly: true }
  );
}

export function trackOrderCompleted(
  tracker: AnalyticsBrowser,
  subscriptionId: string,
  subscription: SubscriptionFragment,
  user?: {
    id: string;
  } & Viewer
) {
  const bikeItem = {
    product_id: subscription.packageSlug,
    sku: subscription.bike?.name || "Buzzbike",
    category: "Bikes",
    name: subscription.bike?.name || "Buzzbike",
    brand: "Buzzbike",
    variant: subscription.bike?.name || "Buzzbike",
    price: subscription.packageBasePriceInPence || 0 / 100,
    quantity: subscription.packageMinimumTermLengthInMonths || 1,
    image_url: subscription.bike?.images?.find((image) => image.isFeatured)
      ?.imageUrl,
    currency: "GBP",
  };

  const cookies = parseCookies();

  const properties = {
    checkout_id: subscriptionId,
    order_id: `order_${tracker.instance?.user().id()}`,
    affiliation: "Signup Page",
    currency: "GBP",
    products: [bikeItem],
    coupon: subscription.discountCode,
    user,
    fbc: cookies._fbc,
    fbp: cookies._fbp,
  };
  trackGenericEvent(tracker, "Order Completed", properties, { all: true });
  trackGenericEvent(
    tracker,
    "Order Completed",
    {
      email: user?.email,
      bikeType: bikeItem.name,
      conversiontype: "Confirmation",
      confirmationref: tracker.instance?.user().id(),
    },
    { roktOnly: true }
  );
  window?.VWO?.event("orderCompleted", {
    email: user?.email,
    bikeType: bikeItem.name,
    packageSlug: subscription.packageSlug,
  });
}

export function trackUpgradeToFullTheftCoverage(
  tracker: ClientOrServerSegment
) {
  trackGenericEvent(tracker, "Upgrade To Full Theft Coverage");
}

export function trackDowngradeToBasicTheftCoverage(
  tracker: ClientOrServerSegment
) {
  trackGenericEvent(tracker, "Downgrade To Basic Coverage");
}

export function trackRiderInfoPack(tracker: ClientOrServerSegment) {
  trackGenericEvent(tracker, "Rider Info Pack");
}

export function trackDownloadApp(
  tracker: ClientOrServerSegment,
  os: "Ios" | "Android"
) {
  trackGenericEvent(tracker, "Download App", {
    os,
  });
}

export function trackSeletedBike(
  tracker: ClientOrServerSegment,
  bikeName: string
) {
  trackGenericEvent(tracker, "Selected Bike", {
    bikeName,
    acValue: bikeName,
  });
}

export function trackSeletedCity(
  tracker: ClientOrServerSegment,
  cityName: string
) {
  trackGenericEvent(tracker, "Selected City", {
    cityName,
    acValue: cityName,
  });
}

export function trackBoughtBike(
  tracker: ClientOrServerSegment,
  bikePrice?: number
) {
  trackGenericEvent(tracker, "Bought Bike", {
    bikePrice,
    acValue: bikePrice,
  });

  window?.VWO?.event("bikePurchased", {
    bikePrice,
  });
}

export function trackPlanChanged(
  tracker: ClientOrServerSegment,
  packageSlug: string,
  changeType: "switch" | "renew"
) {
  trackGenericEvent(tracker, "Plan Changed", {
    packageSlug,
    changeType,
  });

  window?.VWO?.event("planChanged", {
    packageSlug,
    changeType,
  });
}

export function trackSignedUp(
  tracker: AnalyticsBrowser,
  email?: string,
  bikeType?: string
) {
  trackGenericEvent(tracker, "Signed Up", { method: "Email" });
  trackGenericEvent(
    tracker,
    "Signed Up",
    {
      email,
      bikeType,
      conversiontype: "Account",
      confirmationref: tracker.instance?.user().id(),
    },
    { roktOnly: true }
  );
}

export function trackLogin(tracker: ClientOrServerSegment) {
  trackGenericEvent(tracker, "Signed In", { method: "Email" });
}

export function trackUpdateRider(
  tracker: ClientOrServerSegment,
  riderInfo: UpdateRiderInput
) {
  trackGenericEvent(tracker, "Update Rider", riderInfo);
}

export function trackReferCopied(
  tracker: ClientOrServerSegment,
  referCode: string
) {
  trackGenericEvent(tracker, "Referral Copied", { referCode });
}

export async function trackABTest(
  context: GetServerSidePropsContext,
  properties: { testName: string; variant: string }
) {
  const tracker = await getTrackFunction(context.req, context.res);
  trackGenericEvent(tracker, "AB Test", properties);
}

export function trackSwitchToNoMaintenance(
  tracker: ClientOrServerSegment,
  packageSlug: string
) {
  trackGenericEvent(tracker, "Switch To No Maintenance", { packageSlug });
}

export function testEvent(
  tracker: ClientOrServerSegment,
  side: "client" | "server",
  properties: Record<string, unknown>
) {
  if (side === "client") {
    trackGenericEvent(tracker, "Test Event Client-side", properties);
  } else {
    trackGenericEvent(tracker, "Test Event Server-side", properties);
  }
}
